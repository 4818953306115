import React, { useState } from "react";
import styles from "./Accordion.module.css";
import { ChevronDown, ChevronUp } from "lucide-react";

export const accordionData = [
  {
    id: 1,
    title: "What is Shop from Bharat?",
    content: `Shop from Bharat is an online platform that enables users from outside india to purchase authentic indian goods directly from indian brands. We aim to bring the richness and diversity of Indian Products to the global market, with a focus on quality, reliability, and cultural authenticity.`,
  },
  {
    id: 2,
    title: "What countries can Shop from Bharat deliver to?",
    content: `Currently, Shop from Bharat delivers to Singapore, the Middle East, Thailand and Vietnam, with a focus on English-speaking segments. We are continually expanding our delivery network, so stay tuned for updates on new locations. We will soon expand to delivering worldwide.`,
  },
  {
    id: 3,
    title: "How do I place an order on Shop from Bharat?",
    content: (
      <div className={styles.accordion_three}>
        <div>
          You can place an order on Shop from Bharat using two convenient
          methods:
        </div>
        <div className={styles.accordion_points}>
          <div>
            1.<strong> Assisted purchase</strong>: Simply paste the link of the
            product you wish to purchase, sign up, and our team will reach out
            to you for more details and payment. We'll handle the entire
            purchasing process on the respective websites for you and keep you
            updated on delivery and other details.
          </div>

          <div>
            2.<strong> Virtual Indian Address</strong>: We provide you with a
            virtual address in Inida. You can use this address to place orders
            on any Indian website. You can track your orders yourself, and once
            we receive all your packages at the virtual address, we'll
            consolidate them and ship them to your international address.
            Shipping charges will be collected once all your packages are ready
            to be shipped. Just signup on the website and you will recevie from
            virtual address.
          </div>
        </div>
      </div>
    ),
  },
  {
    id: 4,
    title: "What type of products can i buy from Shop from Bharat?",
    content: `Shop from Bharat offers a wide variety of products, including traditional Indian clothing, handicrafts, home decor, jewelry, species, and more. Our platform is designed to showcase the best of Indian craftsmanship and culture, catering to global audience.`,
  },
  {
    id: 5,
    title: "How are the products sourced and guranteed authentic?",
    content: `We partner directly with reputable Indian brands and artisans to ensure that all products on Shop from Bharat are authentic and of the highest quality. We carefully vet our partners and regularly review product quality to maintain the highest standards. Each purchase supports local indian businesses and artisans, helping to preserve and promote Indian heritage.`,
  },
];

const Accordion = () => {
  const [activeId, setActiveId] = useState(null);

  const handleActive = (id) => {
    setActiveId((prevActiveId) => (prevActiveId === id ? null : id));
  };

  return (
    <div className={styles.accordionMain}>
      <div className={styles.accordion_heading}>
        <h1>Frequently asked questions</h1>
      </div>
      <div className={styles.accordion}>
        {accordionData.map(({ title, content, id }) => (
          <div className={styles.accordion_item} key={id}>
            <div
              className={styles.accordion_title}
              onClick={() => handleActive(id)}
            >
              <div className={styles.accordion_mainTitle}>{title}</div>
              <div>{activeId === id ? <ChevronDown /> : <ChevronUp />}</div>
            </div>
            {activeId === id && (
              <div className={styles.accordion_content}>{content}</div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Accordion;
