import { useEffect } from "react";
import "./App.css";
import Accordion from "./Components/Accordion/Accordion";
import Categories from "./Components/Categories/Categories";
import Footer from "./Components/Footer/Footer";
import Header from "./Components/Header/Header";
import SearchBar from "./Components/SearchBar/SearchBar";
import Services from "./Components/Services/Services";
import Sites from "./Components/Sites/Sites";
import toast from "react-hot-toast";
import VirtualAddress from "./Components/VirtualAddress/VirtualAddress";

function HomePage() {
  // useEffect(() => {
  //   toast.success(
  //     "Your query has been registered and we will get back to you with product details and payment link via whatsapp or email.",
  //     {
  //       duration: 5000,
  //       position: "top-right",
  //       iconTheme: {
  //         primary: "#15803d",
  //         secondary: "#fff",
  //       },
  //       // Aria
  //       ariaProps: {
  //         role: "status",
  //         "aria-live": "polite",
  //       },
  //     }
  //   );
  // }, []);

  const token = localStorage.getItem("JWT_TOKEN");

  return (
    <>
      <div className="app">
        <Header token={token} />
        {token && <VirtualAddress />}
        <Categories />
        <Sites />
        <Services />
        <Accordion />
      </div>
      <Footer />
    </>
  );
}

export default HomePage;
