import React from "react";
import styles from "./Virtual.module.css";

const Virtual = () => {
  return (
    <div className={styles.virtual_page}>
      <div className={styles.virtual_page_step}>
        <div className={styles.step_video}>
          <iframe
            width="450"
            height="275"
            src="https://www.youtube.com/embed/ugrAo8wEPiI?si=qji1sS3EZveyvTVq"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
        <div className={styles.step_info}>
          <h3>1. SIGN UP AND GET A UNIQUE ADDRESS</h3>
          <p>
            Sign up with our easy online registration form and instantly get
            your own free Unique address in India to Start shopping straight
            away
          </p>
        </div>
      </div>
      <div className={styles.virtual_page_step}>
        <div className={styles.step_info}>
          <h3>2. START SHOPPING</h3>
          <p>
            Shop from local and international top brands and get deals from
            online stores in India.
          </p>
        </div>
        <div className={styles.step_video}>
          <iframe
            width="450"
            height="275"
            src="https://www.youtube.com/embed/ugrAo8wEPiI?si=qji1sS3EZveyvTVq"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <div className={styles.virtual_page_step}>
        <div className={styles.step_video}>
          <iframe
            width="450"
            height="275"
            src="https://www.youtube.com/embed/ugrAo8wEPiI?si=qji1sS3EZveyvTVq"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
        <div className={styles.step_info}>
          <h3>3. WAIT FOR YOUR ORDERS</h3>
          <p>
            After you place your orders, we receieve and consolidate them at the
            chosen Shop from Bharat Virtual Address.
          </p>
        </div>
      </div>
      <div className={styles.virtual_page_step}>
        <div className={styles.step_info}>
          <h3>4. PAYMENT AND DELIVERY</h3>
          <p>
            From your account on Shop from Bharat, you can manage, ship, and
            track all your packages from India, till delivery to your doorstep.'
            to 'Get regular updates from Shop From India team till you recieve
            your order.
          </p>
        </div>
        <div className={styles.step_video}>
          <iframe
            width="450"
            height="275"
            src="https://www.youtube.com/embed/ugrAo8wEPiI?si=qji1sS3EZveyvTVq"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Virtual;
