import React, { useState, useEffect } from "react";
import Navbar from "../Header/Navbar";
import styles from "./Works.module.css";
import Virtual from "./Virtual";
import AssistedPurchase from "./AssistedPurchase";
import "react-tooltip/dist/react-tooltip.css";
import Footer from "../Footer/Footer";

const Works = () => {
  const [tab, setTab] = useState(0);
  const token = localStorage.getItem('JWT_TOKEN')


  const toggleTab = (val) => {
    setTab(val);
  };

  return (
    <div className={styles.works}>
      <div className={styles.works_container}>
        <Navbar token={token} />
        <div className={styles.works_info}>
          <div className={styles.works_header}>
            <div className={styles.horizontal_tabs}>
              <button
                style={tab === 0 ? { borderBottom: "3px solid #fe5402" } : {}}
                onClick={() => toggleTab(0)}
              >
                Assisted Purchase
              </button>
              <button
                style={tab === 1 ? { borderBottom: "3px solid #fe5402" } : {}}
                onClick={() => toggleTab(1)}
              >
                Virtual Indian Address
              </button>
            </div>
          </div>
          {tab === 1 ? <Virtual /> : <AssistedPurchase />}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Works;
