import React from "react";
import styles from "./Footer.module.css";
import FrameImg from "../../assets/Frame.png";
import TwitterSvg from "../../assets/twitter.svg";
import InstaSvg from "../../assets/instagram.svg";
import LinkedSvg from "../../assets/linkedin.svg";
import TiktokSvg from "../../assets/tiktok.svg";
import FaceBookSvg from "../../assets/facebook.svg";


const Footer = () => {
  return (
    <div className={styles.footer}>
     <div className={styles.footer_container}>
     {/* <div className={styles.footer_content}>
        <div className={styles.footer_col}>
          <h3>Products</h3>
          <ul>
            <li>Pricing</li>
            <li>Road Map</li>
            <li>Security</li>
            <li>FAQ</li>
            <li>Features</li>
          </ul>
        </div>
        <div className={styles.footer_col}>
          <h3>Our Partners</h3>
          <ul>
            <li>Installation Manual</li>
            <li>Release Note</li>
            <li>Community Help</li>
          </ul>
        </div>
        <div className={styles.footer_col}>
          <h3>Company</h3>
          <ul>
            <li>About Us</li>
            <li>Career</li>
            <li>Press</li>
            <li>Support</li>
          </ul>
        </div>
        <div className={styles.footer_col}>
          <h3>Contact Us</h3>
          <div className={styles.footer_input}>
            <input className={styles.input} type="text" placeholder="Enter your Email" />
            <button className={styles.button}>Subscribe</button>
          </div>
          <ul className={styles.socials_list}>
            <li className={styles.social_media}><img src={TwitterSvg} alt="" /></li>
            <li className={styles.social_media}><img src={InstaSvg} alt="" /></li>
            <li className={styles.social_media}><img src={LinkedSvg} alt="" /></li>
            <li className={styles.social_media}><img src={TiktokSvg} alt="" /></li>
            <li className={styles.social_media}><img src={FaceBookSvg} alt="" /></li>
          </ul>
        </div>
      </div> */}
      <div className={styles.footer_copyright}>
        <div className={styles.footer_brand}>
          <img src={FrameImg} alt="" />
        </div>
        <div className={styles.copyright}>&copy; 2024 All Rights Reserved</div>
      </div>
     </div>
    </div>
  );
};

export default Footer;


