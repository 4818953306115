import React from "react";
import styles from "./AssistedPurchase.module.css";

const AssistedPurchase = () => {
  return (
    <div className={styles.assisted_page}>
      <div className={styles.assisted_page_step}>
        <div className={styles.step_video}>
          <iframe
            width="450"
            height="275"
            src="https://www.youtube.com/embed/ugrAo8wEPiI?si=qji1sS3EZveyvTVq"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
        <div className={styles.step_info}>
          <h3>1. PASTE THE PRODUCT LINK ON OUR PLATFORM</h3>
          <p>
            Simply browse & copy the product link from any Indian e-commerce
            website and paste it into our platform. We'll handle the rest,
            ensuring a seamless shopping experience for you.
          </p>
        </div>
      </div>
      <div className={styles.assisted_page_step}>
        <div className={styles.step_info}>
          <h3>2. SIGN UP AND PROVIDE CONTACT DETAILS</h3>
          <p>
            Sign up with your details, and provide the necessary contact
            information. This helps us reach out to you for further
            instructions, payment confirmation and deliver the packages.
          </p>
        </div>
        <div className={styles.step_video}>
          <iframe
            width="450"
            height="275"
            src="https://www.youtube.com/embed/ugrAo8wEPiI?si=qji1sS3EZveyvTVq"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <div className={styles.assisted_page_step}>
        <div className={styles.step_video}>
          <iframe
            width="450"
            height="275"
            src="https://www.youtube.com/embed/ugrAo8wEPiI?si=qji1sS3EZveyvTVq"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
        <div className={styles.step_info}>
          <h3>3. CONNECT FOR PREFERNECES AND PAYMENTS </h3>
          <p>
            We'll connect with you to confirm your product preferences and
            finalize payment. Your order will be placed as per your
            specifications.
          </p>
        </div>
      </div>
      <div className={styles.assisted_page_step}>
        <div className={styles.step_info}>
          <h3>4. WE PLACE THE ORDER AND KEEP YOU UPDATED</h3>
          <p>
            We handle the entire ordering process and keep you informed with
            regular updates on your order's status and delivery timeline.
          </p>
        </div>
        <div className={styles.step_video}>
          <iframe
            width="450"
            height="275"
            src="https://www.youtube.com/embed/ugrAo8wEPiI?si=qji1sS3EZveyvTVq"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <div className={styles.assisted_page_step}>
        <div className={styles.step_video}>
          <iframe
            width="450"
            height="275"
            src="https://www.youtube.com/embed/ugrAo8wEPiI?si=qji1sS3EZveyvTVq"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
        <div className={styles.step_info}>
          <h3>5. RECEIVE YOUR ORDER AT YOUR DOORSTEP </h3>
          <p>
            Sit back and relax as we deliver your order directly to your
            doorstep, ensuring a hassle-free shopping experience.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AssistedPurchase;
