import React, { useState } from "react";
import styles from "./Categories.module.css";
import BrowseSvg from "../../assets/browsing.svg";
import PasteSvg from "../../assets/paste.svg";
import WaitSvg from "../../assets/wait.svg";
import PaymentSvg from "../../assets/payment.svg";
import ReceiveSvg from "../../assets/recieve-order.svg";
import SignUpSvg from "../../assets/virtual-address.svg";
import PlaceOrderSvg from "../../assets/place-order.svg";
import { Tooltip } from "react-tooltip";
import { Link } from "react-router-dom";

const items = [
  {
    itemSrc: BrowseSvg,
    itemName: "Browse products from your favorite indian website",
  },
  {
    itemSrc: PasteSvg,
    itemName: "Submit link in the above input box & enter details",
  },
  {
    itemSrc: WaitSvg,
    itemName: " Wait for us to share payment details with you",
  },
  {
    itemSrc: PaymentSvg,
    itemName: "Make the payment to place an order",
  },
  {
    itemSrc: ReceiveSvg,
    itemName: "Receive the order",
  },
];

const virtual_items = [
  {
    itemSrc: SignUpSvg,
    itemName: "Signup and get your virtual address",
  },
  {
    itemSrc: PlaceOrderSvg,
    itemName: "Place order on Indian website using this address",
  },
  {
    itemSrc: WaitSvg,
    itemName: "Wait for us to receive the packages",
  },
  {
    itemSrc: PaymentSvg,
    itemName: "Pay shipping charges to us",
  },
  {
    itemSrc: ReceiveSvg,
    itemName: "Receive Order",
  },
];

const Categories = () => {
  const [tab, setTab] = useState(0);

  const toggleTab = (val) => {
    setTab(val);
  };

  return (
    <div className={styles.categories}>
      <div className={styles.categories_container}>
        <div className={styles.categories_header}>
          <div className={styles.heading}>
            <h2>How it Works</h2>
            <div
              className={styles.question_mark}
              id="question-mark"
              style={{ cursor: "pointer", color: "#000" }}
            >
            <Link to="/how-it-works"><h2>?</h2> </Link>
            </div>
            {
              <Tooltip
                anchorSelect="#question-mark"
                place="top"
                className={styles.toolTip}
              >
                <div>How it really works...</div>
              </Tooltip>
            }
          </div>
          <div className={styles.horizontal_tabs}>
            <button
              style={tab === 0 ? { borderBottom: "3px solid #fe5402" } : {}}
              onClick={() => toggleTab(0)}
            >
              Assisted Purchase
            </button>
            <button
              style={tab === 1 ? { borderBottom: "3px solid #fe5402" } : {}}
              onClick={() => toggleTab(1)}
            >
              Virtual Indian Address
            </button>
          </div>
        </div>
        <div className={styles.categories_items}>
          {tab === 0
            ? items.map((item, index) => (
                <div className={styles.categories_item} key={index}>
                  <div className={styles.categories_item_image}>
                    <img
                      src={item.itemSrc}
                      alt=""
                      style={{
                        width: "30%",
                        filter:
                          "brightness(0) saturate(100%) invert(39%) sepia(92%) saturate(2129%) hue-rotate(357deg) brightness(105%) contrast(107%)",
                      }}
                    />

                    <div className={styles.itemName}>{item.itemName}</div>
                  </div>
                </div>
              ))
            : virtual_items.map((item, index) => (
                <div className={styles.categories_item} key={index}>
                  <div className={styles.categories_item_image}>
                    <img
                      src={item.itemSrc}
                      alt=""
                      style={{
                        width: "35%",
                        filter:
                          "brightness(0) saturate(100%) invert(39%) sepia(92%) saturate(2129%) hue-rotate(357deg) brightness(105%) contrast(107%)",
                      }}
                    />

                    <div className={styles.itemName}>{item.itemName}</div>
                  </div>
                </div>
              ))}
        </div>
      </div>
    </div>
  );
};

export default Categories;
