import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Login from "./Components/Auth/Login/Login";
import Signup from "./Components/Auth/Signup/Signup";
import Address from "./Components/Auth/Address/Address";
import VerifyOtp from "./Components/Auth/VerifyOtp/VerifyOtp";
import HomePage from "./Homepage";
import Works from "./Components/Works/Works";
import ForgotPassword from "./Components/Auth/ForgotPassword/ForgotPassword";
import { Toaster } from "react-hot-toast";
import ChangePassword from "./Components/Auth/ChangePassword/ChangePassword";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/signup",
        element: <Signup />,
      },
      {
        path: "/address",
        element: <Address />
      },
      {
        path: "/verify-otp",
        element: <VerifyOtp />
      },
      {
        path: '/how-it-works',
        element: <Works />
      },
      {
        path: '/forgot-password',
        element: <ForgotPassword />
      },
      {
        path: '/change-password/:id',
        element: <ChangePassword />
      }
    ],
  },
]);


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
     <Toaster toastOptions={{
        success: {
          style: {
            background: '#fff',
            color: '#15803d'
          },
        },
        error: {
          style: {
            background: '#fff',
            color: '#dc2626'
          },
        },
      }} />
    <RouterProvider router={router} />
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
