import React, { useState } from "react";
import styles from "./Header.module.css";
import HeaderImg from "../../assets/content.png";
import FrameImg from "../../assets/Frame.png";
import CartImg from "../../assets/Buy.png";
import ProfileImg from "../../assets/Ellipse.png";
import SearchSvg from "../../assets/Search.svg";
import { Menu, X } from "lucide-react";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import { Tooltip } from "react-tooltip";

const Header = ({token}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className={styles.header}>
      <div className={styles.container}>
        <Navbar token={token} />
      </div>
      <div className={styles.header_content}>
        <div className={styles.header_info}>
          <div className={styles.header_main}>
            <h2>
              Shop Products from <span>BHARAT</span>
            </h2>
          </div>
          <div className={styles.header_paragraph}>
            <p>
              Bringing you the best selection of Authentic Indian Goods and
              Products Delivered Right to your doorstep
            </p>
          </div>
          <div className={styles.header_main_input}>
            <div className={styles.header_input}>
              <input placeholder="Paste Your Link" />
              <button>Submit</button>
            </div>
            <div
              className={styles.question_mark}
              id="question-mark"
              style={{ cursor: "pointer", color: "#000" }}
            >
                 <Link to="/how-it-works"><h2>?</h2> </Link>
            </div>
            {

              <Tooltip
                anchorSelect="#question-mark"
                place="top"
                className={styles.toolTip}
              >
              <div>How it really works... 
              </div>
              </Tooltip>

            }
          </div>
        </div>
        <div className={styles.header_img}>
          <img src={HeaderImg} alt="header image" />
        </div>
      </div>
    </header>
  );
};

export default Header;
