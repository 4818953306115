import React, { useState } from "react";
import styles from "./Header.module.css";
import FrameImg from "../../assets/Frame.png";
import CartImg from "../../assets/Buy.png";
import ProfileImg from "../../assets/Ellipse.png";
import SearchSvg from "../../assets/Search.svg";
import { Menu, X } from "lucide-react";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Navbar = ({token}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate()

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };


  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("JWT_TOKEN");
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("bharatId");
    localStorage.removeItem("userName");
    localStorage.removeItem("email");
    navigate("/login")
  };
  
  const handLeLogin = () => {
    navigate("/login")
  }

  const { pathname } = useLocation();

  return (
    <>
      <nav className={styles.navbar}>
        <Link to="/" className={styles.brand}>
          <img src={FrameImg} alt="" />
        </Link>
        <div className={styles.hamburger} onClick={toggleMenu}>
          <Menu />
        </div>
        <ul className={`${styles.navlinks} ${isMenuOpen ? styles.open : ""}`}>
          {isMenuOpen && (
            <div className={styles.navbar_close} onClick={toggleMenu}>
              <X />
            </div>
          )}
          <li
            className={
              pathname === "/how-it-works"
                ? styles.on_works_page
                : styles.how_it_works
            }
          >
            <Link to="/how-it-works">How it Works</Link>
          </li>
          {/* <div className={styles.inputWrapper}>
              <img
                src={SearchSvg}
                alt="search icon"
                className={styles.searchIcon}
              />
              <input
                className={styles.input}
                placeholder="Search for Products"
              />
            </div> */}
          {/* <div className={styles.nav_right}> */}
          {token ? (
            <li className={styles.profileWrapper} onClick={toggleDropdown}>
              <img className={styles.profile} src={ProfileImg} alt="" />
              {isDropdownOpen && (
                <div className={styles.dropdownMenu}>
                  <button onClick={handleLogout} className={styles.logoutBtn}>
                    Logout
                  </button>
                </div>
              )}
            </li>
          ): (
            <li className={styles.profileWrapper} onClick={toggleDropdown}>
              <img className={styles.profile} src={ProfileImg} alt="" />
              {isDropdownOpen && (
                <div className={styles.dropdownMenu}>
                  <button onClick={handLeLogin} className={styles.logoutBtn}>
                    Login
                  </button>
                </div>
              )}
            </li>
          )}
          {/* </div> */}
        </ul>
      </nav>
    </>
  );
};

export default Navbar;
